.page-container {
  max-width: 80vw;
  max-height: fit-content;
  margin: auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

.navigation-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
}

/* Style the logo */
.title-logo {
  height: 100%;
  border-radius: 50%;
  text-align: center;
  color: black;
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.title {
  font-size: xx-large;
  font-weight: 600;
}

/* Style the navigation bar */
.nav {
  height: 100%;
  color: black;
  box-sizing: border-box;
  display: flex;
}

/* Style the navigation links */
.nav a {
  display: block;
  padding: 10px;
  color: black;
  text-decoration: none;
}

.clickable-title-logo {
  color: black;
  text-decoration: none;
}

/* Change the links on hover */
.nav a:hover {
  text-decoration: underline;
}

.rsvp-button-container {
  width: 200px;
}

/* Style the content container */
.page-content {
  height: 65vh;
  max-height: fit-content;
  padding: 20px;
  box-sizing: border-box;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}

.head {
  width: 100px;
  height: 100px;
}

.rotate-left {
  transform: rotate(30deg);
}

.rotate-right {
  transform: rotate(-30deg);
}

.story {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: 20px;
}

.story img {
  width: 400px;
  justify-self: center;
  object-fit: scale-down;
}

/* for smaller screens */
@media (max-width: 600px) {
  .logo {
    display: none;
  }
  .navigation-container {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 10px 25px;
  }
  .story img {
    width: 80vw;
    justify-self: center;
  }
  .page-content {
    height: unset;
    min-height: fit-content;
  }
  .footer {
    margin-top: 5px;
  }
  .head {
    width: 50px;
    height: 50px;
  }
  .story {
    height: 60vh;
    width: unset;
  }
}